import { MantineThemeOverride } from '@mantine/core'
import { breakpoints } from 'config/mq'

export const landingTheme = ({ color }: { color?: string }): MantineThemeOverride => ({
  breakpoints,
  colors: {
    brand: color
      ? [color, color, color, color, color, color, color, color, color, color]
      : ['#E7F5FF', '#D0EBFF', '#A5D8FF', '#74C0FC', '#4DABF7', '#339AF0', '#228BE6', '#1C7ED6', '#1971C2', '#1864AB'],
  },
  primaryColor: 'brand',
  loader: 'dots',
})
