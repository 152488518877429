export const breakpoints = {
  xs: 500,
  sm: 800,
  md: 1000,
  lg: 1200,
  xl: 1400,
}

// media query
export const mq = {
  xs: `@media (min-width: ${breakpoints.xs}px)`,
  sm: `@media (min-width: ${breakpoints.sm}px)`,
  md: `@media (min-width: ${breakpoints.md}px)`,
  lg: `@media (min-width: ${breakpoints.lg}px)`,
  xl: `@media (min-width: ${breakpoints.xl}px)`,
}

export const breakpointsBox = {
  mobile: 320,
  tablet: 500,
  desktop: 1200,
}

// media query
export const mqBox = {
  tablet: `@media (min-width: ${breakpointsBox.tablet}px)`,
  desktop: `@media (min-width: ${breakpointsBox.desktop}px)`,
}
