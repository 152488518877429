import { createTRPCClient } from '@trpc/client'
import { createReactQueryHooks } from '@trpc/react'
import { AppRouter } from 'server/routers/app'

import type { inferProcedureOutput } from '@trpc/server'
import { trpcConnectString } from 'config/config'
/**
 * Enum containing all api query paths
 */
export type TQuery = keyof AppRouter['_def']['queries']
/**
 * This is a helper method to infer the output of a query resolver
 * @example type HelloOutput = InferQueryOutput<'hello'>
 */
export type InferQueryOutput<TRouteKey extends TQuery> = inferProcedureOutput<AppRouter['_def']['queries'][TRouteKey]>

export const trpc = createReactQueryHooks<AppRouter>()

export const tprcVanila = createTRPCClient<AppRouter>({
  url: trpcConnectString,
})
