import { Button, SimpleGrid, Text } from '@mantine/core'
import platform from 'platform'
import { Component, ErrorInfo, ReactNode } from 'react'
import { tprcVanila } from 'utils/trpc'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    let platformInformation = ''
    try {
      platformInformation = JSON.stringify(
        {
          name: platform.name, // 'IE'
          version: platform.version, // '10.0'
          layout: platform.layout, // 'Trident'
          os: platform.os, // 'Windows Server 2008 R2 / 7 x64'
          description: platform.description, // 'IE 10.0 x86 (platform preview; running in IE 7 mode) on Windows Server 2008 R2 / 7 x64'

          product: platform.product, // 'iPad'
          manufacturer: platform.manufacturer, // 'Apple'
        },
        null,
        2
      )
    } catch (error) {}
    tprcVanila.mutation('public.thereIsError', {
      errorStack: errorInfo.componentStack,
      errorString: `${JSON.stringify(error, Object.getOwnPropertyNames(error), 2)}`,
      location: window.location.href,
      platformInformation,
    })
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div
          css={{
            padding: 12,
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <SimpleGrid>
            <Text
              css={{
                display: 'block',
                width: '100%',
              }}
              align="center"
              size="xl"
            >
              Упс, ошибка!
            </Text>
            <Button
              size="lg"
              onClick={() => {
                location.reload()
              }}
            >
              Перезагрузить страницу
            </Button>
          </SimpleGrid>
        </div>
      )
    }

    return this.props.children
  }
}
