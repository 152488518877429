import { isProduction } from 'utils/isProduction'

const protocol = isProduction ? 'https' : 'http'
const domain = isProduction ? 'vingla.ru' : 'localhost:3558'

export const devPort = 3558
export const prodPort = 7000

export const trpcConnectString =
  typeof window == 'undefined' ? `http://localhost:${isProduction ? prodPort : devPort}/api/trpc` : '/api/trpc'

export const config = {
  idYandex: isProduction ? `7a142f3af0204de8a72afd22a46a8438` : '67a98ca91a9a4a1c98a1b4456ded4268',
  idGoogle: isProduction
    ? `797769944635-10l9f87rfd4168jisaheo77knma6ikv6.apps.googleusercontent.com`
    : '797769944635-daobdo3kjhqts184tlktu7h0iovqok3d.apps.googleusercontent.com',
  protocol,
  domain,
  url: `${protocol}://${domain}`,

  paidPlaceStorageLimitMB: 500,
  freePlaceStorageLimitMB: 50,

  trialCountDays: 7,
}
