import { NotificationsProvider } from '@mantine/notifications'
import { withTRPC } from '@trpc/next'
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary'
import { trpcConnectString } from 'config/config'
import { AppProps } from 'next/app'
import { AppRouter } from 'server/routers/app'
import { Text, MantineProvider } from '@mantine/core'
import { landingTheme } from 'utils/landingTheme'

export function App(props: AppProps) {
  const { Component, pageProps } = props

  return (
    <>
      <ErrorBoundary>
        <MantineProvider withGlobalStyles withNormalizeCSS theme={landingTheme({})}>
          <NotificationsProvider>
            <Component {...pageProps} />
          </NotificationsProvider>
        </MantineProvider>
      </ErrorBoundary>
    </>
  )
}

export default withTRPC<AppRouter>({
  config({ ctx }) {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */

    const url = trpcConnectString
    return {
      url,
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
      // headers: () => {
      //   return { token: `${Date.now()}` }
      // },
      // fetch(url, options) {
      //   return fetch(url, {
      //     ...options,
      //     credentials: 'include',
      //   })
      // },
      headers() {
        return {
          cookie: ctx?.req?.headers.cookie,
          publichost: ctx?.req?.headers.host,
        }
      },
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: true,
})(App)
